<template>
	<div>
		<div class="header">
			<div class="top top_mafi f-left">
				<div class="util">
					<button
						@click="$emit('goBack')"
						class="arrow_white"
					>
						<i class="icon-arrow-left"></i>
					</button>
				</div>
				<h2 class="page_tit">{{ text_title }}</h2>
				<div class="save">
					<button
						@click="postBoardConfig"
						class="btn_save"
						:disabled="is_save"
					>{{  $language.common.complete }}</button>
				</div>
			</div>
		</div>
		<!-- //고정해더 -->
		<div class="section_wrap pt-50">
			<!-- 내용 -->
			<div class="container">
				<div class="subindex_mainbox">

					<div class="input_area">
						<div class="bright_input">
							<label for="cartelDesc">{{  $language.subscribe.title_subscribe_input_name }} <!-- 구독 게시판 이름 --></label>
							<div class="input_utill">
								<input
									v-model="item_board_config.board_name"
									type="text" id="cartelDesc" :placeholder="$language.notice.name_require" maxlength="20"
								>
								<div class="input_utill_area">
									<div
										v-if="item_board_config.board_name"
										class="btn_del"
									>
										<button
											@click="item_board_config.board_name = ''"
											class="active"
										><span class="hide">{{  $language.common.close }}</span></button>
									</div>
									<span class="input_text_count">{{ item_board_config.board_name.length }}/20</span>
								</div>
							</div>
						</div>
					</div>

					<div class="input_area">
						<div class="flex align_items_center justify_content_between">
							<div class="tit mb-0">{{  $language.subscribe.title_subscribe_permission_admin }} <!-- 운영자 권한 설정 --></div>
							<p class="toggle-group h_unset">
								<span
									class="btn-toggle " :class="{ on: item_board_config.admin_subscrp_board_mangement_authority_fg == 'Y'}"
									@click="item_board_config.admin_subscrp_board_mangement_authority_fg = item_board_config.admin_subscrp_board_mangement_authority_fg == 'Y' ? 'N' : 'Y' "
								><em class="hide">{{  $language.common.join_confirm.name }}</em></span>
							</p>
						</div>
						<p class="input_desc pb-0">
							{{  $language.subscribe.txt_subscribe_permission_admin }}
							<!-- 운영자에게 카르텔 구독 게시판(등록, 수정, 삭제)에 대한 권한을 줍니다. -->
						</p>
					</div>

					<div class="input_area">
						<div class="flex align_items_center justify_content_between">
							<div class="tit mb-0">{{  $language.subscribe.title_subscribe_permission_open }} <!-- 구독 게시판 공개 설정 --> </div>
							<p class="toggle-group h_unset">
								<!-- TODO : 수신 허용시 .on 클래스 추가 -->
								<span
									class="btn-toggle " :class="{ on: item_board_config.board_release_fg == 'Y'}"
									@click="item_board_config.board_release_fg = item_board_config.board_release_fg == 'Y' ? 'N' : 'Y' "
								><em class="hide">{{  $language.common.join_confirm.name }}</em></span>
							</p>
						</div>
						<p class="input_desc pb-0">
							{{  $language.subscribe.txt_subscribe_permission_open}}
							<!-- 카르텔 가입된 회원에게만 게시판을 공개합니다 -->
						</p>
					</div>
				</div>
			</div>

			<!-- //내용 -->
		</div>
	</div>
</template>

<script>
export default {
	name: 'SubscribeSetting'
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: this.$language.notice.add
				, title: this.$language.notice.add
				, not_footer: true
				, not_header: true
				, type: 'cartel_sub'

			}
			, item_cartel: {}
			, item_board_config: {
				board_name: ''
				, cartl_number: this.$route.params.idx
				, member_number: this.user.member_number
				, board_release_fg: 'Y'
				, admin_subscrp_board_mangement_authority_fg: 'Y'
			}
		}
	}
	, computed: {
		is_save: function(){
			let t = true
			if(this.item_board_config.board_name != ''){
				t = false
			}

			return t
		}
		, text_title: function(){
			let t = this.program.title
			if(this.$route.params.b_id){
				t = this.$language.notice.edit
			}
			return t
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getBoardConfig: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_board_config
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_number: this.$route.params.b_id
					}
					, type: true
				})
				if(result.success){
					this.item_board_config = result.data
					this.$set(this.item_board_config, 'admin_subscrp_board_mangement_authority_fg', result.data.board_authority.admin_authority_fg)
					this.$set(this.item_board_config, 'board_release_fg', result.data.release_fg)

					this.$set(this.item_board_config, 'board_number', this.$route.params.b_id)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, updateBoardConfig: async function(){

		}
		, postBoardConfig: async function(){
			try{
				if(!this.item_board_config.board_name){
					throw this.$language.notice.name_require
				}
				this.$bus.$emit('on', true)
				let url = this.$api_url.api_path.post_subscribe_request
				if(this.$route.params.b_id){
					url = this.$api_url.api_path.post_subscribe_modify
				}
				const result = await this.$Request({
					method: 'post'
					, url: url
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, board_name: this.item_board_config.board_name
						, board_number: this.item_board_config.board_number
						, board_release_fg: this.item_board_config.board_release_fg
						, admin_subscrp_board_mangement_authority_fg: this.item_board_config.admin_subscrp_board_mangement_authority_fg
					}
					, type: true
				})
				if(result.success){
					if(this.item_board_config.id){
						this.$bus.$emit('notify', { type: 'success', message: this.$language.common.success})
					}else{
						this.$router.back()
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				// this.permission = this.resetPermission
			}
		}
	}
	, async created() {
		this.$bus.$emit('onLoad', this.program)
		await this.getCartel()
		if(this.$route.params.b_id){
			await this.getBoardConfig()
		}
	}
}
</script>

<style>
.color-primary { color: var(--blue01) !important;}
</style>